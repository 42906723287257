@import "../../globalStyle.scss";

.contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p.rodo-text {
        font-size: 0.8rem;
        margin-top: 5px;
        text-align: center;
        width: 400px;
        font-weight: 500;

        @media screen and (max-width: 500px) {
            width: 90%;
        }
    }

    p.contact-file-text {
        font-size: 0.6rem;
        margin-top: -3px;
    }

    input[type="text"], input[type="email"] {
        outline: none;
        border: 1.9px solid $fontColor;
        width: 20%;
        height: 20px;
        border-radius: 3px;
        padding: 8px;
        margin-bottom: 10px;
        color: #5D5B5B;
        font-size: 1.1rem;
        transition: all 0.2s ease;

        &:hover {
            width: 21%;
        }

        &:focus {
            width: 21%;
            color: #000;
        }
    }

    textarea {
        outline: none;
        border: 1.9px solid $fontColor;
        min-width: 25%;
        max-width: 50%;
        min-height: 150px;
        max-height: 450px;
        border-radius: 3px;
        padding: 8px;
        margin-bottom: 10px;
        color: #5D5B5B;
        font-size: 0.9rem;
    }

    input[type="file"] {
        margin-bottom: 15px;
    }

    input[type="submit"] {
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        border: 1.9px solid $fontColor;
        width: 240px;
        height: 60px;
        border-radius: 10px;
        color: $fontColor;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        margin-top: 10px;
    
        &:hover {
            background-color: $fontColor;
            color: $secFontColor;
        }
    }

    button {
        border: 1.9px solid $fontColor;
        color: $fontColor;

        &:hover {
            background-color: $fontColor;
            color: $secFontColor;
        }
    }

    @media screen and (max-width: 1025px) {
        input[type="text"], input[type="email"] {
            width: 250px;

            &:hover {
                width: 260px;
            }
    
            &:focus {
                width: 260px;
                color: #000;
            }
        }

        textarea {
            width: 500px;
            height: 150px;
            resize: none;
        }
    }

    @media screen and (max-width: 1650px) {
        input[type="text"], input[type="email"] {
            width: 200px;

            &:hover {
                width: 210px;
            }
    
            &:focus {
                width: 210px;
                color: #000;
            }
        }

        textarea {
            min-width: 400px;
            height: 150px;
        }
    }

    @media screen and (max-width: 650px) {
        input[type="text"], input[type="email"] {
            width: 90%;

            &:hover {
                width: 90%;
            }
    
            &:focus {
                width: 90%;
                color: #000;
            }
        }

        textarea {
            max-width: 90%;
            height: 150px;
            resize: none;
        }
    }

    @media screen and (max-width: 425px) {
        input[type="submit"] {
            width: 90%;
        }

        textarea {
            min-width: 0;
            width: 90%;
            height: 150px;
            resize: none;
        }
    }
}

