@import "../../globalStyle.scss";

.image-carousel-container {
    position: relative;
    height: 40vw; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    
    a {
        position: relative;
        font-size: 1.35rem;
        font-weight: 500;
        text-decoration: none;
        color: $fontColor;
        top: 30px;
        text-align: center;
        padding-bottom: 15px;
    }

    .image-carousel {
        position: relative;
        overflow: hidden;
        
        .carousel-left-button {
            content: '';
            position: absolute;
            background: $defaultGradient;
            opacity: 0.7;
            z-index: 3;
            width: 70px;
            height: 100%;
            left: 0;
            cursor: pointer;
            transition: opacity 0.4s ease;

            &:hover {
                opacity: 0.85;
            }

            &::after {
                content: '';
                width: 64px;
                height: 64px;
                position: absolute;
                z-index: 4;
                top: 50%;
                transform: translate(0%, -50%);
                background-image: url("../../Images/arrowWhite.png");
            }
        }

        .carousel-right-button {
            content: '';
            position: absolute;
            background: $defaultGradient;
            opacity: 0.7;
            z-index: 3;
            width: 70px;
            height: 100%;
            right: 0;
            cursor: pointer;
            transition: opacity 0.4s ease;

            &:hover {
                opacity: 0.85;
            }

            &::after {
                content: '';
                width: 64px;
                height: 64px;
                position: absolute;
                z-index: 4;
                top: 50%;
                transform: translate(10%, -50%) rotate(180deg);
                background-image: url("../../Images/arrowWhite.png");
            }
        }

        .carousel-images {
            position: relative;
            display: flex;
            transition: transform 0.4s ease-in-out;

            img {
                min-width: 70vw;
                height: 40vw;
            }
        }

        .carousel-radio-buttons {
            width: 100%;
            height: 30px;
            position: absolute;
            bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            input[type="radio"] {
                border-radius: 15px;
                border-style: solid;
                border-color: white;
                border-width: 1px;
                width: 12px;
                height: 12px;
                cursor: pointer;
                -webkit-appearance: none;
                transition: background 0.4s ease;
                outline: none;

                &:hover {
                    background: rgb(215, 215, 215);
                }

                &:checked {
                    background: white;
                }
            }

            input[type="radio"].selected {
                background: white;
            }
        }

        @media screen and (max-width: 735px) {
            .carousel-left-button {
                width: 40px;

                &::after {
                    width: 40px;
                    left: -10px;
                }
            }

            .carousel-right-button {
                width: 40px;

                &::after {
                    width: 40px;
                    right: -5px;
                }
            }
        }
    }
}

@media screen and (max-width: 1150px) {
    .image-carousel-container {
        width: 100vw;
        height: 60vw;

        .image-carousel { 
            .carousel-images {
                img {
                    min-width: 100vw;
                    height: 60vw;
                }
            }
        }
    }  
}

@media screen and (max-width: 700px) {
    .image-carousel-container {
        width: 100vw;
        height: 100vw;

        .image-carousel { 
            .carousel-images {
                img {
                    min-width: 100vw;
                    height: 100vw;
                }
            }
        }
    }  
}