@import "../globalStyle.scss";

.navbar {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $defaultGradient;
    color: $secFontColor;
    position: fixed;
    z-index: 10;

    a {
        color: $secFontColor;
        margin-left: 15px;
        font-weight: 500;
    }

    p {
        margin-right: 15px;
    }

    @media screen and (max-width: 640px) {
        height: auto;
        justify-content: center;
        flex-direction: column;
        padding-top: 15px;
        
        a, p {
            margin-left: 0;
            margin-right: 0;
            text-align: center;
        }

        a {
            margin-bottom: 10px;
        }
        p {
            margin-top: 10px;
        }
    }
}

.gallery-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    position: relative;
    top: 100px;
    user-select: none;

    .folder {
        position: relative;
        width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        height: auto;
        margin-bottom: 15px;

        img {
            position: absolute;
            width: 128px;
            height: 128px;
            top: 25px;
        }

        p {
            position: relative;
            text-align: center;
            width: 100%;
            height: auto;
            top: 60px;
            font-size: 1.6rem;
            overflow-wrap: break-word;
        }
    }

    img {
        width: 400px;
        height: 400px;
        margin-bottom: 25px;
        transition: all 0.4s ease;

        &:hover {
            transform: scale(1.05);
            -webkit-transform: scale(1.05);
            -apple-transform: scale(1.05);
        }

        @media screen and (max-width: 780px) {
            width: 100%;
            
            &:hover {
                transform: scale(1.0);
                -webkit-transform: scale(1.0);
                -apple-transform: scale(1.0);
            }
        }
    }

    @media screen and (max-width: 640px) {
        top: 170px;
    }
}

.active-image {
    display: none;
    position: fixed;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -apple-transform: translate3d(0, 0, 0);
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background: rgba(0, 0, 0, 0.8);
    user-select: none;

    .active-image-img {
        position: absolute;
        z-index: 21;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            position: relative;     
            max-width: 90%;
            max-height: 90%;
        }
    }
}

.active-image.active {
    display: flex;
    justify-content: center;
    align-items: center;

}