
@import "../../globalStyle.scss";

.site-widget {
    position: relative;
    width: 100%;
    height: 500px;
    top: 300px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $widgetGradient;
        opacity: 1;
        clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);
    }

    .site-widget-header {
        color: $secFontColor;
        font-size: 3rem;
        font-weight: 500;
        position: relative;
        left: 35px;
        top: 25px;
        z-index: 2;
        width: 650px;
    }

    .site-widget-description {
        color: $secFontColor;
        font-size: 1.4rem;
        position: relative;
        left: 35px;
        z-index: 2;
        width: 700px;

        @media screen and (max-width: 800px) {
            font-size: 1.2rem;
        }

        @media screen and (max-width: 500px) {
            font-size: 1.0rem;
        }
    }
}

.site-widget-empty {
    position: relative;
    width: 100%;
    top: 300px;
    height: auto;
    color: $fontColor;

    .site-widget-empty-header {
        position: relative;
        text-align: center;
        font-size: 3rem;
    }

    .site-widget-empty-description {
        position: relative;
        font-size: 1.7rem;
        padding-left: 25px;
    }

    .site-widget-component {
        width: 100%;
    }
}

@media screen and (max-width: 1035px) {
    .site-widget {
        height: auto;
        padding-bottom: 10px;
        
        &::after {
            clip-path: none;
        }

        .site-widget-header {
            width: 90%;
            left: 15px;
        }

        .site-widget-description {
            margin-left: auto;
            margin-right: auto;
            width: 94%;
            left: 0;
        }
    }

    .site-widget-empty {
        height: auto;
        padding-bottom: 10px;
    }
}