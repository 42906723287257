@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

$defaultGradient: linear-gradient(60deg, rgba(72,85,99,1) 0%, rgba(41,50,60,1) 100%);
$widgetGradient: linear-gradient(71deg, rgba(41,50,60,1) 25%, rgba(72,85,99,0.36) 100%);
$fontColor: #29323C;
$secFontColor: rgb(255, 255, 255);
$font: 'Montserrat', sans-serif;
$backgroundColor: #F5F5F5;

body {
    padding: 0;
    margin: 0;
    background-color: $backgroundColor;
    font-family: $font;
    color: $fontColor;
    font-size: 20px;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: $fontColor;
}

.site-button {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    border: 2px solid rgb(255, 255, 255);
    width: 240px;
    height: 60px;
    border-radius: 10px;
    color: $secFontColor;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: rgba(255, 255, 255, 255);
        color: $fontColor;
    }
}
