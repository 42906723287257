@import "../../globalStyle.scss";

.contact-text {
    position: relative;
    top: 370px;
    text-align: center;
    font-size: 1.45rem;
}

.bus-details {
    position: relative;
    top: 370px;
    z-index: 2;
    width: 100%;
    height: 150px;
    background: $defaultGradient;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .detail {
        width: 270px;
        height: 75px;
        color: $secFontColor;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;

        img {
            position: relative;
            padding-right: 15px;
            width: 38px;
        }

        a {
            text-decoration: none;
            color: $secFontColor;
        }
    }
}

.down-footer {
    position: relative;
    top: 370px;
    width: 100%;
    height: 35px;
    background-color: #171717;
    color: $secFontColor;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .footer-element-left {
        margin-left: 15px;
    }

    .footer-element-right {
        margin-right: 15px;
    }
}

@media screen and (max-width: 1205px) {
    .contact-text {
        top: 400px;
    }

    .bus-details {
        top: 400px;
        flex-direction: column;
        height: auto;
        padding-top: 25px;
        padding-bottom: 5px;
        text-align: center;
    }

    .down-footer {
        top: 400px;
    }
}

@media screen and (max-width: 800px) {
    .down-footer {
        flex-direction: column;
        text-align: center;
        height: auto;
    }
}