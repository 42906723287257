@import "../../globalStyle.scss";

.prices-container {
    width: 100%;
    height: auto;

    p {
        text-align: center;
    }

    .prices-container-element {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;

        table, tr, td {
            border: 1px solid $fontColor;
            border-collapse: collapse;
            padding: 8px;

            @media screen and (max-width: 1080px) {
                width: 95%;
            }

            @media screen and (max-width: 500px) {
                width: 95%;
                padding: 3px;
                font-size: 0.9rem;
            }

            @media screen and (max-width: 450px) {
               font-size: 0.8rem;
            }
        }     
    }

    .small-text {
        font-size: 0.7rem;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}