@import "../../globalStyle.scss";

.nav-open-but {
    position: absolute;
    background-image: url("../../Images/nav.png");
    width: 64px;
    height: 64px;
    right: 90px;
    top: 15px;
    cursor: pointer;
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;

    @media screen and (max-width: 1000px) {
        visibility: visible;
        opacity: 1;
    }
}
.nav-open-but.open {
    visibility: hidden;
    opacity: 0;
}

.nav-close-but {
    position: absolute;
    background-image: url("../../Images/close.png");
    width: 64px;
    height: 64px;
    right: 90px;
    top: 15px;
    cursor: pointer;
    z-index: 11;
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;
}
.nav-close-but.open {
    visibility: visible;
    right: 40px;
    opacity: 1;
}

.site-header {
    position: relative;
    width: 100%;
    height: 550px;

    .site-header-background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $defaultGradient;
        clip-path: polygon(0% 0%,100% 0%,100% 75%,0% 97%);  
    }

    .site-header-left-top {
        position: relative;
        width: 260px;
        margin-left: 90px;
        padding-top: 15px;
        display: flex;
        align-items: center;

        img {
            width: 64px;
        }

        .site-header-name {
            font-weight: 500;
            color: $secFontColor;
            margin-left: 0px;
        }
    }

    .site-header-navbar {
        position: absolute;
        width: auto;
        right: 90px;
        top: -10px;

        p {
            color: $secFontColor;
        }

        nav {
            ul {
                display: flex;

                li {
                    position: relative;
                    padding: 0px 10px;
                    font-size: 1.3rem;
                    font-weight: 500;

                    p {
                        cursor: pointer;
                        transition: all 0.4s ease;
                        
                        &:hover {
                            color: rgb(175, 175, 175);
                        }
                    }
                }
            }
        }
    }

    .site-header-left-box {
        position: relative;
        width: 600px;
        margin-left: 90px;
        top: 80px;

        p {
            font-size: 1.5rem;
            color: $secFontColor;
        }
    }


    @media screen and (max-width: 1200px) {
        .site-header-left-box {
            top: 60px;
            p {
                font-size: 1.3rem;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        .site-header-navbar {
            position: fixed;
            right: -320px;
            top: 0;
            z-index: 10;
            background: $defaultGradient;
            width: 300px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 10px 0px #000;
            transition: right 0.4s ease;

            nav {
                position: relative;
                left: -15px;
                ul {
                    flex-direction: column;
                    text-align: center;
                    align-items: center;

                    li {
                        padding: 25px 0px;
                    }
                }
            }
        }

        .site-header-navbar.open {
            right: 0;
        }
    }

    @media screen and (max-width: 715px) {
        .nav-open-but {
            right: 7px;
        }

        .nav-close-but {
            right: 7px;
        }

        .site-header-left-top {
            margin-left: 5px;
        }

        .site-header-left-box {
            margin-left: 15px;
        }
    }

    @media screen and (max-width: 585px) {
        .site-header-left-box {
            margin-left: 15px;
            width: 94%;
        }

        button {
            left: 50%;
        }
    }
}

.site-second-header {
    position: relative;
    width: 560px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    top: 60px;
    padding-bottom: 5px;

    .site-second-arrow-down {
        position: absolute;
        background: url("../../Images/arrow.png");
        width: 64px;
        height: 64px;
        transform: rotate(-90deg) translate(-50%, -50%);
        transition: all 0.4s ease;
        cursor: pointer;

        &:hover {
            margin-top: 10px;
        }
    }

    @media screen and (max-width: 715px) {
        font-size: 1.3rem;
        width: 500px;

        .site-second-arrow-down {
            margin-top: -20px;
        }
    }

    @media screen and (max-width: 530px) {
        font-size: 1.3rem;
        width: 100%;

        .site-second-arrow-down {
            margin-top: -20px;
        }
    }
}